<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="site-banner">
          <h1>
            <span v-if="isEmpty(siteUrl)">{{ siteName }}</span>
            <a v-else :href="siteUrl" _target="self">{{ siteName }}</a>
          </h1>
        </div>
      </v-col>
    </v-row>
    <!-- Channel list -->
    <v-row align="center" justify="center">
      <v-col
        cols="4"
        v-for="(channel, index) in channels"
        :key="channel.type + index"
      >
        <span class="channel-cell">
          <a
            :href="channel.url"
            _target="_blank"
            :alt="getChannelName(channel.type)"
          >
            <img
              :src="getChannelImage(channel.type, channel.logo)"
              :class="siteId == 7 ? 'teaxs-channel-logo' : 'channel-logo'"
            />
          </a>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
import ChannelTypeEnum from "./../enum/channelTypeEnum";

export default {
  name: "Landing",
  components: {},
  data() {
    return {
      siteName: "Page not found",
      siteUrl: "",
      siteId: 0,
      channels: [],
    };
  },
  computed: {
    ...mapGetters(["LIST_ACTIVE_CHANNELS_DATA"]),
  },
  watch: {
    LIST_ACTIVE_CHANNELS_DATA() {
      let res = this.LIST_ACTIVE_CHANNELS_DATA;
      let site_data = res.site;
      let channels_data = res.channels;
      this.siteName = site_data.name;
      this.siteUrl = site_data.url;
      this.channels = [];
      this.channels = channels_data;
      // channels_data.forEach((site) => {
      //   this.channels.push({
      //     type: site.type,
      //     url: site.url,
      //   });
      // });
    },
  },
  created() {
    this.siteId = this.$route.params.siteId;
    this.getActiveChannels(this.siteId);
  },
  methods: {
    isEmpty: function (data) {
      if (
        data == undefined ||
        data == null ||
        data.length == 0 ||
        data == 0 ||
        data == ""
      ) {
        return true;
      }
      return false;
    },
    getActiveChannels: function (siteId) {
      let filter = {
        id: siteId,
        // params: {
        //   param1: 1,
        // },
      };
      this.isLoading = true;
      this.GET_LIST_ACTIVE_CHANNELS(filter)
        .then(
          function () {
            this.isLoading = false;
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false;
          }.bind(this)
        );
    },
    getChannelName: function (type) {
      switch (type) {
        case ChannelTypeEnum.ZALO_OA:
          return "Zalo";
        case ChannelTypeEnum.FACEBOOK:
          return "Facebook";
        case ChannelTypeEnum.INSTAGRAM:
          return "Instagram";
        case ChannelTypeEnum.TIKTOK:
          return "Tiktok";
        case ChannelTypeEnum.YOUTUBE:
          return "Youtube";
        case ChannelTypeEnum.GOOGLE_MAP:
          return "Google Map";
      }
      return "Unknown";
    },
    getChannelImage: function (type, logo) {
      if (!this.isEmpty(logo)) {
        return logo;
      }
      switch (type) {
        case ChannelTypeEnum.ZALO_OA:
          return require("../assets/zalo.png");
        case ChannelTypeEnum.FACEBOOK:
          return require("../assets/facebook.png");
        case ChannelTypeEnum.INSTAGRAM:
          return require("../assets/instagram.jpeg");
        case ChannelTypeEnum.TIKTOK:
          return require("../assets/tiktok.jpeg");
        case ChannelTypeEnum.YOUTUBE:
          return require("../assets/youtube.png");
        case ChannelTypeEnum.GOOGLE_MAP:
          return require("../assets/googlemap.jpeg");
      }
      return "";
    },
    ...mapActions(["GET_LIST_ACTIVE_CHANNELS"]),
  },
};
</script>

<style scoped>
.site-banner {
  padding-top: 65px;
  padding-bottom: 15px;
}
.channel-logo {
  padding: 15px 0 15px 0 !important;
  width: 70%;
  max-width: 120px;
  /* height: 128px; */
}
.teaxs-channel-logo {
  padding: 5px 0 5px 0 !important;
  width: 85%;
  max-width: 300px;
}
.channel-cell {
  padding: 25px 0px 25px 0px !important;
  margin: 15px;
}
</style>
