import { createStore } from "vuex";
import site from "./site";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    site,
  },
});
