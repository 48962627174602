import commonRequest from "./../../api/index";

const state = {
  activate_channels: [],
};

const getters = {
  LIST_ACTIVE_CHANNELS_DATA: (state) => {
    return state.activate_channels;
  },
};

const actions = {
  GET_LIST_ACTIVE_CHANNELS: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/${payload.id}/get_channels/`,
        payload,
        function (res) {
          context.commit("GET_LIST_ACTIVE_CHANNELS_HANDLER", res.data);
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  GET_LIST_ACTIVE_CHANNELS_HANDLER: (state, payload) => {
    state.activate_channels = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
