const ChannelTypeEnum = {
  UNKNOWN: 0,
  ZALO_OA: 1,
  FACEBOOK: 2,
  INSTAGRAM: 3,
  TIKTOK: 4,
  YOUTUBE: 5,
  GOOGLE_MAP: 6,
};

export default ChannelTypeEnum;
